/****
 *
 * window & process.env constants
 *
 */
export const ELOQUENT = window.Eloquent;
export const AXIOS = window._axios;

export const APP_ENV = process.env.REACT_APP_ENV;
export const APP_URL = process.env.REACT_APP_URL;
export const API_URL = process.env.REACT_APP_API_URL;

/****
 *
 * Storage keys
 *
 */
export const STORAGEKEY_AUTH_TOKEN = 'sk-authToken';
export const STORAGEKEY_APP_LANGUAGE = 'sk-appLanguage';

/****
 *
 * Configs
 *
 */
// App default language (also used for the <html /> lang='' attribute)
// see https://developer.paciellogroup.com/blog/2016/06/using-the-html-lang-attribute/
// NOTE: make sure the language key defined here matches the default helmet key
// // see `executors/i18n/helmet`
export const APP_LANGUAGE_DEFAULT = 'en';

// App environments
// see .env.production & .env.development
// values must match the values defined in these files
export const APP_ENVS = {
    LOCAL: 'local',
    STAGING: 'staging',
    PRODUCTION: 'production'
};

// SENTRY - see https://docs.sentry.io/error-reporting/configuration/?platform=javascript
export const SENTRY_CONFIG = {
    environment: APP_ENV,
    dsn: 'https://d677eea5410649d4bf9ae5b70b52f6f6@sentry.io/1853558'
};

// WEBFONT config - see https://github.com/typekit/webfontloader
export const WEBFONT_CONFIG = {
    google: {
        families: [
            'Poppins:300',
            'Poppins:400',
            'Poppins:600',
            'Poppins:700',
            'Poppins:900'
        ]
    }
};

// FACEBOOK app id
export const FACEBOOK_APP_ID = 'TODO_REPLACE';

/****
 *
 * App routing
 *
 */
export const ROUTES = {
    DASHBOARD: '/'
};

export const ROUTE_REDIRECT_AFTER_REGISTRATION = ROUTES.DASHBOARD;
export const ROUTE_REDIRECT_AFTER_LOGIN = ROUTES.DASHBOARD;
export const ROUTE_REDIRECT_FROM_UNAUTHED_ROUTE = ROUTES.DASHBOARD;
export const ROUTE_REDIRECT_FROM_AUTHED_ROUTE = ROUTES.DASHBOARD;

/****
 *
 * Project config
 *
 */

export const AKE_TEL = '+44 (0) 203 816 9970';
export const AKE_EMAIL = 'enquiries@akegroup.com';

export const METABASE_URL = 'https://metabase.paris.createl.la';
