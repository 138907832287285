/* eslint-disable react/jsx-max-props-per-line */

import PropTypes from 'prop-types';
import React, { Component, Suspense } from 'react';
import AppLoading from '../Loading/AppLoading';
import { Switch, Route } from 'react-router-dom';
import { ROUTES } from 'config/constants';
// import { UnauthedRoute, AuthedRoute } from './components/RouteWrappers';
import {
    DashboardContainer,
    PageNotFoundContainer
} from './config/routes';

export default class AppRouter extends Component {
    static propTypes = {
        isAuthed: PropTypes.bool.isRequired
    }

    shouldComponentUpdate(nextProps) {
        const { isAuthed } = this.props;

        if (isAuthed !== nextProps.isAuthed) {
            return true;
        }

        return false;
    }

    render() {
        const { isAuthed } = this.props;

        return (
            <Suspense fallback={<AppLoading />}>
                <Switch>
                    <Route path={ROUTES.DASHBOARD} exact={true} component={DashboardContainer} isAuthed={isAuthed} />

                    <Route component={PageNotFoundContainer} />
                </Switch>
            </Suspense>
        );
    }
}
