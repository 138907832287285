
export const DEFAULT = 'An unknown error occured. If you have a stable internet connection, the error automatically got sent to us for further investigation.';

export const AUTH_LOGIN_401 = 'Invalid email or password.';
export const AUTH_LOGIN_422 = 'Please verify your email first by following the instructions sent to you via email.';

export const AUTH_REGISTER_422 = 'An account with this E-mail address already exists.';
export const AUTH_SEND_RESET_PASSWORD_CODE_404 = 'No account found with that email address.';
export const AUTH_RESET_PASSWORD_404 = 'Invalid link, make sure you copied the whole password reset link from your email.';
export const AUTH_RESET_PASSWORD_422 = 'This link already expired.';
