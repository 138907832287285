import * as Sentry from '@sentry/browser';
import { replace } from 'connected-react-router';
import { register, login, logout, setApiRequestToken, unsetApiRequestToken } from 'api/auth';
import { getAuthedProfile, updateUserProfile } from 'api/user';
import { setAuthToken, getAuthToken, deleteAuthToken } from 'utils/helpers';
import {
    ROUTE_REDIRECT_AFTER_LOGIN,
    ROUTE_REDIRECT_AFTER_REGISTRATION
} from 'config/constants';

const IS_APP_READY = 'IS_APP_READY';

const AUTH_START = 'AUTH_START';
const AUTH_SUCCESS = 'AUTH_SUCCESS';
const AUTH_FAILED_LOGIN = 'AUTH_FAILED_LOGIN';
const AUTH_FAILED_REGISTRATION = 'AUTH_FAILED_REGISTRATION';
const AUTH_LOGOUT_START = 'AUTH_LOGOUT_START';
const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';

const UPDATE_PROFILE_START = 'UPDATE_PROFILE_START';
const UPDATE_PROFILE = 'UPDATE_PROFILE';

export const appIsReady = () => ({
    type: IS_APP_READY
});

const authStart = () => ({
    type: AUTH_START
});

const authSuccess = (profile) => ({
    type: AUTH_SUCCESS,
    profile
});

const loginError = () => ({
    type: AUTH_FAILED_LOGIN
});

const registrationError = () => ({
    type: AUTH_FAILED_REGISTRATION
});

const authLogoutStart = () => ({
    type: AUTH_LOGOUT_START
});

const authLogoutSuccess = () => ({
    type: AUTH_LOGOUT_SUCCESS
});

const updateProfileStart = () => ({
    type: UPDATE_PROFILE_START
});

const updateProfile = (profile) => ({
    type: UPDATE_PROFILE,
    profile
});

export function handleLogin(email, password, isRemember) {
    return async (dispatch) => {
        dispatch(authStart());

        try {
            const { data: { token } } = await login(email, password);

            setAuthToken(token, isRemember);
            setApiRequestToken(token);

            const { data } = await getAuthedProfile();

            dispatch(authSuccess(data));
            dispatch(replace(ROUTE_REDIRECT_AFTER_LOGIN));
        } catch (err) {
            dispatch(loginError());
        }
    };
}

export function handleRegistration(params, redirectRoute = ROUTE_REDIRECT_AFTER_REGISTRATION) {
    return async (dispatch) => {
        dispatch(authStart());

        try {
            const { data: { token } } = await register(params);

            setAuthToken(token);
            setApiRequestToken(token);

            const { data } = await getAuthedProfile();

            dispatch(authSuccess(data));

            if (redirectRoute) {
                dispatch(replace(redirectRoute));
            }
        } catch (err) {
            dispatch(registrationError());
        }
    };
}

export function handleAutoLogin() {
    return async (dispatch) => {
        try {
            const token = getAuthToken();

            if (token !== null) {
                setApiRequestToken(token);

                const { data } = await getAuthedProfile();

                dispatch(authSuccess(data));
            }
        } catch (err) {
            dispatch(handleLogout(true));
        } finally {
            dispatch(appIsReady());
        }
    };
}

export function handleLogout(isAutoLogout = false) {
    return async (dispatch) => {
        dispatch(authLogoutStart());
        deleteAuthToken();

        try {
            await logout();
        } finally {
            unsetApiRequestToken();

            if (isAutoLogout === true) {
                dispatch(authLogoutSuccess());
            } else {
                window.location = window.location.origin;
            }
        }
    };
}

export function handleUpdateUserProfile(id, params) {
    return async (dispatch) => {
        dispatch(updateProfileStart());

        try {
            const { data } = await updateUserProfile(id, params);

            dispatch(updateProfile(data));
        } catch (err) {
            dispatch(updateProfile({}));
        }
    };
}

const INITIAL_STATE = {
    isAppReady: false,
    isAuthenticating: false,
    isUpdatingProfile: false,
    isAuthed: false,
    isLoggingOut: false,

    profile: {}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case IS_APP_READY:
            return {
                ...state,
                isAppReady: true
            };

        case AUTH_START:
            return {
                ...state,
                isAuthenticating: true
            };

        case AUTH_FAILED_LOGIN:
        case AUTH_FAILED_REGISTRATION:
            return {
                ...state,
                isAuthenticating: false
            };

        case AUTH_SUCCESS: {
            const { profile } = action;
            const { id, email } = profile;

            Sentry.configureScope((scope) => {
                scope.setUser({ id, email });
            });

            return {
                ...state,
                isAuthenticating: false,
                isAuthed: true,
                profile
            };
        }

        case UPDATE_PROFILE_START:
            return {
                ...state,
                isUpdatingProfile: true
            };

        case UPDATE_PROFILE: {
            const { profile } = action;

            return {
                ...state,
                isUpdatingProfile: false,
                profile: {
                    ...state.profile,
                    ...profile
                }
            };
        }

        case AUTH_LOGOUT_START:
            return {
                ...state,
                isLoggingOut: true
            };

        case AUTH_LOGOUT_SUCCESS:
            return INITIAL_STATE;

        default:
            return state;
    }
};
