// import i18next from 'i18next';
import {
    DEFAULT,
    AUTH_LOGIN_401, AUTH_LOGIN_422,
    AUTH_REGISTER_422,
    AUTH_SEND_RESET_PASSWORD_CODE_404, AUTH_RESET_PASSWORD_404, AUTH_RESET_PASSWORD_422
} from './messages-error';

export function getMessageByResponseError({ status, endpoint }) {
    if (status >= 500) {
        return DEFAULT;
    }

    switch (endpoint) {
        case 'Auth.login':
            switch (status) {
                case 401: return AUTH_LOGIN_401;
                case 422: return AUTH_LOGIN_422;
                default: return false;
            }

            // case 'Auth.register':
            //     return status === 422 && i18next.t('AUTH_REGISTER_422');

        case 'Auth.register':
            return status === 422 && AUTH_REGISTER_422;

        case 'Auth.sendResetPasswordCode':
            return status === 404 && AUTH_SEND_RESET_PASSWORD_CODE_404;

        case 'Auth.resetPassword':
            switch (status) {
                case 404: return AUTH_RESET_PASSWORD_404;
                case 422: return AUTH_RESET_PASSWORD_422;
                default: return false;
            }

        default:
            return false;
    }
}
