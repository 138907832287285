import * as Sentry from '@sentry/browser';
import { store } from 'redux/store';
import { pushAlert, ALERT_TYPES } from 'creatella-react-components/lib/reduxReducers/alerts';
import { getMessageByResponseError } from './apiIntercepor-utils/error';
import { getMessageByResponseSuccess } from './apiIntercepor-utils/success';
import { AXIOS } from 'config/constants';

if (AXIOS) {
    const { dispatch } = store;

    const onError = (response, err) => {
        let message = '';

        if (response) {
            const { status } = response;

            if (status >= 500) {
                Sentry.captureException(response);
            }

            message = getMessageByResponseError(response);
        } else {
            Sentry.captureException(err);
        }

        if (message) {
            dispatch(pushAlert({
                type: ALERT_TYPES.ERROR,
                message
            }));
        }
    };

    AXIOS.interceptors.response.use(
        (response) => {
            if (response.status >= 200 && response.status < 300) {
                const message = getMessageByResponseSuccess(response);

                if (message) {
                    dispatch(pushAlert({
                        type: ALERT_TYPES.SUCCESS,
                        message
                    }));
                }

                return response;
            }

            onError(response);

            const err = new Error(response.statusText);

            err.response = response;

            throw err;
        },
        (err) => {
            onError(err.response, err);

            return Promise.reject(err);
        }
    );
}
