import {
    getAppLanguage as getAppLanguageCache,
    setAppLanguage as setAppLanguageCache
} from 'utils/helpers';

const SET_APP_LANGUAGE = 'SET_APP_LANGUAGE';

export function setAppLanguage(language, i18n) {
    return {
        type: SET_APP_LANGUAGE,
        language,
        i18n
    };
}

const initialState = {
    language: getAppLanguageCache()
};

export default function i18n(state = initialState, action) {
    switch (action.type) {
        case SET_APP_LANGUAGE: {
            const { language, i18n } = action;

            setAppLanguageCache(language);
            i18n.changeLanguage(language);

            return {
                ...state,
                language
            };
        }

        default:
            return state;
    }
}
