// import i18next from 'i18next';
import {
    AUTH_LOGOUT
} from './messages-success';

export function getMessageByResponseSuccess({ endpoint }) {
    switch (endpoint) {
        // case 'Auth.logout':
        //     return i18next.t('AUTH_LOGOUT');

        case 'Auth.logout':
            return AUTH_LOGOUT;

        default:
            return false;
    }
}
