/***
 *
 * NOTE: This is ONLY for <Helmet /> in `src/containers/App/AppContainer`
 *
 */
import { getAppLanguage } from 'utils/helpers';
import { APP_URL, FACEBOOK_APP_ID } from 'config/constants';

const STATIC_CONFIG = {
    image: `${APP_URL}assets/ake-international.png`,
    siteName: 'AKE Group - Global Intake Dashboards',
    type: 'website',
    language: getAppLanguage(),
    twitterSite: '@akegroup',
    fbAppId: FACEBOOK_APP_ID,
    children: null
};

// HELMET - see https://github.com/SchwSimon/creatella-react-components#helmet-
export default {
    en: {
        ...STATIC_CONFIG,
        description: 'AKE Group\'s Global Intake Dashboards provide realtime analysis on political, security, investment and reputational risks around the world.',
        title: 'AKE Group - Global Intake Dashboards'
    }
    // de: { ... }
    // fr: { ... }
    // ...
};
